.cart{
    z-index: 100;
    position:fixed;
    bottom:300px;
    /* bottom:50%; */
    right: 50px;
    /* border:solid 1px rgba(0,0,0,0.2); */
    border:solid 1px rgba(255,0,0,0.5);
    color:white;

    /* box-shadow: 2px 2px 5px rgba(0,0,0,0.025); */
    box-shadow: 2px 2px 5px rgba(255,0,0,0.025);

    width:50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    flex-direction: column;
    cursor: pointer;

    /* background: linear-gradient( rgb(200, 0, 0) 60%  ,rgb(200, 160, 0) ); */
    background: linear-gradient( rgb(175, 0, 0) 60%  ,rgb(20,20,20) );



}

@media only screen and (max-width:700px){

    .cart{
        /* bottom:40px; */
        right: 20px;

        width:45px;
        height: 45px;
    }
}