

.auth-layout{
    position: absolute;
    /* background-color: red; */
    /* background: linear-gradient(grey ,white); */
    /* border:solid 1px red; */
    /* display: flex;
    justify-content: center; */
    width: 100%;
    height: 100%;
}

.auth-container{
    /* border:solid 1px rgba(0,0,0,0.5); */
    margin:auto;
    border-radius: 5px;
    padding:5px;
    width:fit-content;
    /* position:absolute; */
    /* top:45%; */
    /* left:50%; */
    /* transform: translate(-50% ,-50%); */
    z-index:1;




}

.form{
    display: flex;
    border:solid 1px  rgba(0,0,0,0.3);
    padding: 30px 20px;
    border-radius: 5px;
    flex-direction: column;
    gap:20px;
    min-width:330px;

    box-shadow: 2px 2px 50px rgba(0,0,0,0.25);
}

.form-group{
    display: flex;
    flex-direction: column;
    gap:5px;
    /* border:solid 1px red; */
    padding:3px;
    justify-content: space-evenly;
    align-items: flex-start;

}
.form-group span{
    display: flex;
    align-items: center;
    gap:3px;
    flex: 1;
}

.auth-option{
    display: flex;
    flex-direction: column;
    gap:2px;
}

.auth-option span{
    cursor: pointer;
    transition:all 0.2s ease-in-out
}

.auth-option span:hover{
    color:darkblue;
    text-decoration: underline;
}