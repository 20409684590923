.product-container{
    display: flex;
    gap:45px;
    flex-wrap: wrap;
    justify-content: center;
    padding:20px 0px;
    /* border:solid 1px red; */
    min-height:175px;
    align-items: center;
}


.pagination{
    display:flex ;
    align-items:center;
    gap:15px ;
    cursor:pointer ;
    /* border:solid 1px red; */
    text-align:center ;
    width:fit-content;
    margin:auto;
}

.pagination .left ,.pagination .right{
    border:solid 1px grey;
    width:40px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:5px;
}

.pagination .left:hover ,.pagination .right:hover{
    box-shadow: 1px 1px 2px grey;
}

/* .target{
    color:'red';

} */

.product-item{
    cursor:pointer ;
    border:solid 1px rgba(0,0 ,0,0.2) ;
    padding:5px 7px;
    border-radius:5px;
    background-color:white ;
    box-shadow:1px 1px 5px rgba(0,0,0,0.2);
    /* border:solid 1px red;    */
}

.product-image-container{
    /* border:solid 1px green; */
    overflow: hidden;
    /* backgroundImage:url(${placeholder})` */
    background-size:cover ;
    background-position:center ;
    /* width:180px ;
    height:173px ; */
    width:160px ;
    height:120px ;
    border-radius:15px 0px 15px 0px;
}
.product-image{
    object-fit:cover; 
    /* border:solid 1px red; */
    
    border:solid 0px rgba(0,0,0,0.1) ;
    border-top-left-radius:15px ;
    width:160px;
    height:120px;
    transition:all 0.2s ease-in-out;
}
.product-image:hover{
    transform: scale(1.1);
}
.product-description{
    display:flex ;
    flex-direction:column ;
    padding:5px 7px ;
    border-bottom:0px ;
    background-color:"white"
        
}

@media only screen and (max-width:700px){
    .product-container{
        gap:8px;
        row-gap: 30px;
    }   
    .product-image-container{
        /* width:245px ;
        height:200px ; */
        width:150px ;
        height:100px ;
    }
    .product-description{
        display:flex ;
        flex-direction:column ;
        padding:5px 7px ;
        border-bottom:0px ;
        background-color:"white";
        width:150px;
        font-size: x-small;
    }
    .product-description span{
        font-size: xx-small;
    }
    .product-image{
        /* width:250px;
        height:225px; */
        width:100% ;
        height:100% ;
    }
}