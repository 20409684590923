/* .hero-image{
    height:600px;
    width:100%;
} */
.hero1 ,.hero2 ,.hero3 ,.hero4{
    background-repeat: no-repeat;
    background-size: cover;
    width:90%;
    margin:auto;
    border:solid 0px red;
    background-position: center;
    height: 500px;
    padding:50px 0px;
    color: white;
}
.hero1{
    background-image: url('../images/bg1.jpg');
}

/* .hero2{
    background-image: url('../images/bg2.jpg');
} */

.hero3{
    background-image: url('../images/bg3.jpg');
}
.hero4{
    background-image: url('../images/bg4.jpg');
}

@media only screen and (max-width:700px){
    .hero1 ,.hero2 ,.hero3 ,.hero4{
        width:100%;
        height: 350px;
    }

}