.slider-container {
    position: relative;
    overflow: hidden;
  }
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    justify-content: center;
    border:solid 1px rgba(0,0,0,0.3)
  }
  
  .slide {
    width: 100%;
    height: 450px;
    object-fit: contain; /* Adjust as needed */
    z-index: 1;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  .slide:not(.active) {
    /* opacity: 0; */
    display: none;
    transform: translateX(-100%);
  transition: opacity 0.3s ease-in-out; /* Add separate transition for opacity */

  }
  
  .slider-btn {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    width:50px;height:50px;
    display: flex;justify-content: center;align-items: center;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.3);
    color: #fff; /* Set button color */
    font-size: x-large;
  }  

  .slider-btn:hover {
    opacity: 0.8; /* Add hover effect */
  }
  
  .slider-btn.disabled {
    opacity: 0.5; /* Disable button visually */
    cursor: default; /* Prevent hover effect on disabled buttons */
  }
  

  .prev{
    right:0
  }

  .next{
    left:0
  }


 @media screen and (max-width:700px) {
    .slide {
        height: 400px;
      }  
 }