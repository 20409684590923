.top-header{
    /* border:solid 1px red; */
    position:fixed;
    width:100%;
    /* margin-bottom: 57px; */
    /* background: linear-gradient(45deg, rgb(135, 135, 197) 70% , rgb(82, 161, 82)  ); */
    /* background: linear-gradient(45deg, rgb(117, 167, 117) 70% ,  rgb(98, 98, 214) ); */
    background: linear-gradient(45deg ,rgb(60,60,60) 100% ,transparent);


    /* color:white; */
    box-shadow: 2px 2px 10px gray;
    
}

.site-title{
    color:white;
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
    /* border:solid 1px red; */
}

.header{
    padding:15px;
    padding-left:20px;
    padding-right:20px;

    border:solid 1px grey;
    /* border:solid 1px red; */

    color:white;
    background-color:rgba(50,50,50,1);
    letter-spacing: 1px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left {
    display: flex;
    column-gap: 20px;
}
.header-right{
    display: flex;
    column-gap: 5px;
}

.header-middle{
    display: flex;
    width:550px;
    margin:auto;
    color: black;
    padding:2px;
    flex-direction: column;
    gap:10px;
    /* width:fit */
}
.header-middle form{
    border-radius: 5px;
    display: flex;
    background-color: rgba(0,0,0,0.5);
    border-bottom:solid 1px white;
    align-items: center;
}
.header-search-bar{
    width:100%;
    padding:10px;
    outline: none;
    background-color: white;
    border-radius: 5px;

    border:0;
    color:black
}
.search-btn{
    width:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    height:100%;
    color:white;
    cursor: pointer;
}

.main-content{
    width:65%;
    margin:auto
}

.auth-btn{
    border:solid 1px rgba(0,0,0,0.3);
    cursor: pointer;
    padding:5px 10px;
    border-radius: 5px;
}

.dropMenu{
    /* border:solid 1px red; */
    transition: all 0.5s ease-in-out;
}

.more-option{
    /* border:solid 1px red; */
    position:absolute;
    left:50%;
    transform:translateX(-50%);
    color:rgba(50,50,50,1);
    display: flex;
    column-gap: 15px;
    padding:0px 20px;
    /* background-color: red; */
    z-index: 100;
    color:white;
}
.more-option span{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.more-option span .fas{
    /* color:rgba(0,0,0,0.7); */
    /* font-size: medium; */
}
.more-option b{
    font-size: medium;
    font-weight: lighter;
}

@media only screen and (max-width:900px){
    .header-middle{
        width:400px;
    }
}


@media only screen and (max-width:700px){
    .header-middle{
        width:300px;
    }
    .more-option{
        left:auto;
        right: 0%;
    transform:none;

    }
    .more-option b{
        display: none;
    }
}