.footer-container{
    /* border:solid 1px red; */
    /* background: linear-gradient(270deg, rgb(135, 135, 197) 70% , rgb(82, 161, 82)  ); */
    /* background: linear-gradient(270deg, rgb(117, 167, 117) 70% ,  rgb(98, 98, 214) ); */

    background: linear-gradient(320deg ,rgb(60,60,60) 95% ,transparent);

    color:rgb(200,200,200);

}

.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    gap:20px;

    flex-wrap: wrap;

    padding:20px;
    margin-top: 50px;
    /* margin-bottom: 50px; */
    /* color:rgb(220,220,220); */

    /* background-color:rgb(75,75,75); */
    /* border-top: solid 1px rgba(0,0,0,0.2); */
    /* box-shadow: 10px -0.5px 2px rgba(0,0,0,0.05); */

    /* background-color: 'rgba(0,0,200,0.075)'; */
}

.footer-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    /* margin-top:5px; */
    /* color:rgb(200,200,200); */


}
a{
    color:rgb(200,200,200);
}

@media only screen and (max-width:700px){
    .footer{
        flex-direction:column;
        padding:15px
        /* justify-content: space-around; */
    }
    
}