.main-container{
    width:70%;
    margin:auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-height:60vh;
    
    background-color: rgba(0,0,0,0.025);
    /* background-image: url('../images//placeholder-image.png'); */
}

.toTop{
    position:fixed;
    z-index:5000;
    bottom:40px;
    right: 50px;
    border:solid 1px rgba(0,0,0,0.2);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.025);
    width:50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
}

.custom_loader{
    /* border:solid 1px red; */
    transition:all 0.2s ease-in-out;
    animation-name: anim;
    animation-iteration-count: 1;
    animation-duration: 550ms;
}
@keyframes anim{
    to{
        transform:scale(2)
    }
}

@media only screen and (max-width:700px){
    .main-container{
        width:100%;
    }
    .toTop{
        bottom:40px;
        right: 20px;

        width:45px;
        height: 45px;
    }
}